<template>
  <el-dialog
    title="VMS 모니터링"
    :visible.sync="liveViewDialog"
    @close="cctvModalClose"
    append-to-body
    :closeOnClickModal="false"
    top="5vh"
    class="el-dialog-aside"
    width="1200px"
  >
    <el-container>
      <el-container>
        <!-- <el-main> -->
        <div class="cctv-viewer-wrap">
          <div>
            <dk-hive-webrtc-player
              :server_ip="webRtcInfo.ip"
              :server_port="webRtcInfo.port"
              :video_name="webRtcInfo.videoName"
              ref="dkHiveWebrtcPlayer"
              @onloadWebrtcPlayer="onloadWebrtcPlayer"
              videoHeight="400"
            ></dk-hive-webrtc-player>
          </div>

          <div class="camera-status-monitoring-wrap">
            <ul class="camera-status-monitoring">
              <li>
                <el-button
                  size="mini"
                  type="info"
                  circle
                  @click="ptzLoad()"
                  style=" background-color: rgba(150, 150, 150, 0.7); border: none; padding: 5px;"
                >
                  <i class="fas fa-sync-alt"></i>
                </el-button>
              </li>
              <li>
                <span>{{ selectedVms.fcltName }}</span>
              </li>
            </ul>
          </div>
          <div class="camera-info-wrap">
            <div class="camera-status-wrap">
              <fclt-plugin-status
                ref="fcltPluginStatus"
                :title="'VMS 상태정보'"
                :fcltId="selectedVms.fcltId"
                :statHederInfo="statHederInfo"
              ></fclt-plugin-status>
            </div>
            <div class="cctv-control-wrap">
              <table class="ptz-table">
                <colgroup>
                  <col style="width: 20%;" />
                  <col style="width: 20%;" />
                  <col style="width: 20%;" />
                  <col style="width: 20%;" />
                  <col style="width: 20%;" />
                </colgroup>
                <tr>
                  <td @mousedown="downContinusMove(5)" @mouseup="upContinusMove()" @touchstart="downContinusMove(5)" @touchstop="upContinusMove()">
                    <el-button style="width: 100%;">
                      <i class="fas fa-search-plus"></i>
                    </el-button>
                  </td>
                  <td>&nbsp;</td>
                  <td @mousedown="downContinusMove(1)" @mouseup="upContinusMove()" @touchstart="downContinusMove(1)" @touchstop="upContinusMove()">
                    <el-button style="width: 100%;">
                      <i class="fas fa-arrow-up"></i>
                    </el-button>
                  </td>
                  <td>&nbsp;</td>
                  <td @mousedown="downContinusMove(7)" @mouseup="upContinusMove()" @touchstart="downContinusMove(7)" @touchstop="upContinusMove()">
                    <el-button style="width: 100%;">
                      <i class="fas fa-plus-circle"></i>
                    </el-button>
                  </td>
                </tr>
                <tr>
                  <td>Zoom</td>
                  <td @mousedown="downContinusMove(2)" @mouseup="upContinusMove()" @touchstart="downContinusMove(2)" @touchstop="upContinusMove()">
                    <el-button style="width: 100%;">
                      <i class="fas fa-arrow-left"></i>
                    </el-button>
                  </td>
                  <td>
                    <el-input v-model="controlSpeed" :disabled="true"></el-input>
                  </td>
                  <td @mousedown="downContinusMove(3)" @mouseup="upContinusMove()" @touchstart="downContinusMove(3)" @touchstop="upContinusMove()">
                    <el-button style="width: 100%;">
                      <i class="fas fa-arrow-right"></i>
                    </el-button>
                  </td>
                  <td>Focus</td>
                </tr>
                <tr>
                  <td @mousedown="downContinusMove(6)" @mouseup="upContinusMove()" @touchstart="downContinusMove(6)" @touchstop="upContinusMove()">
                    <el-button style="width: 100%;">
                      <i class="fas fa-search-minus"></i>
                    </el-button>
                  </td>
                  <td>&nbsp;</td>
                  <td @mousedown="downContinusMove(4)" @mouseup="upContinusMove()" @touchstart="downContinusMove(4)" @touchstop="upContinusMove()">
                    <el-button style="width: 100%;">
                      <i class="fas fa-arrow-down"></i>
                    </el-button>
                  </td>
                  <td>&nbsp;</td>
                  <td @mousedown="downContinusMove(8)" @mouseup="upContinusMove()" @touchstart="downContinusMove(8)" @touchstop="upContinusMove()">
                    <el-button style="width: 100%;">
                      <i class="fas fa-minus-circle"></i>
                    </el-button>
                  </td>
                </tr>
              </table>

              <div class="control-slider-wrap">
                <span class="camera-control-speed-label">제어속도</span>
                <el-slider v-model="controlSpeed" :max="64"></el-slider>
              </div>
            </div>
          </div>
        </div>
      </el-container>
      <el-aside width="350px">
        <div class="info-panel">
          <div class="info-panel-wrap">
            <ul v-if="vmsCurrentSchedule.vmsMessages" class="vms-thumbnail-wrap">
              <li v-for="(item, key) in vmsCurrentSchedule.vmsMessages" :key="key">
                <div class="image-wrap">
                  <div class="text">
                    <p>{{ item.vmsMessageName }}</p>
                    <p class="type-text">{{ getVmsMessageLabel(item) }}</p>
                  </div>
                  <div class="image">
                    <img :src="item.thumbImageFile" style="height: auto; width: 100%;" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </el-aside>
    </el-container>
  </el-dialog>
</template>

<script>
import dkHiveWebRtcPlayer from '@/components/webrtc/dkHiveWebRtcPlayer.vue';
import io from 'socket.io-client';
import fcltPluginStatus from '@/traffic/widget/fcltPluginStatus.vue';
import vmsConst from '@/traffic/vmsConst.json';

export default {
  name: 'dk-cctv-viewer',
  components: {
    'dk-hive-webrtc-player': dkHiveWebRtcPlayer,
    'fclt-plugin-status': fcltPluginStatus,
  },
  created() {},
  destroyed() {
    this.socket.disconnect();
  },
  props: {},
  data() {
    const _this = this;
    return {
      socket: null,
      liveViewDialog: false,
      activeName: 'first',
      fcltList: [],
      selectedVms: {
        fcltName: '',
        fcltId: '',
      },
      webRtcInfo: {
        ip: '',
        port: 0,
        videoName: '',
      },
      controlSpeed: 30,
      gimpoCctvProtocolId: '',
      statHederInfo: [
        {
          key: 'powerStatus',
          name: '전원',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
        {
          key: 'doorStatus',
          name: '문 열림',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
        {
          key: 'fanStatus',
          name: '팬 가동',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
        {
          key: 'heaterStatus',
          name: '히터 가동',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
      ],
      vmsDownloadedSchedule: [],
      vmsCurrentSchedule: {},
      vmsConst: vmsConst,
    };
  },

  methods: {
    async selectData(row) {
      try {
        this.selectedVms = row;
        this.cctvActivate();
      } catch (err) {
        console.log(err);
      }
    },
    async cctvActivate() {
      try {
        if (this.$refs.dkHiveWebrtcPlayer) this.$refs.dkHiveWebrtcPlayer.stop();

        if (this.selectedVms.fcltTypeData.managementNumber) this.gimpoCctvProtocolId = this.selectedVms.fcltTypeData.managementNumber.value;
        else this.gimpoCctvProtocolId = '';

        this.webRtcInfo.port = this.selectedVms.fcltTypeData.webRtcPort.value || 0;
        this.webRtcInfo.ip = this.selectedVms.fcltTypeData.webRtcIp.value || '';
        this.webRtcInfo.videoName = this.selectedVms.fcltTypeData.webRtcVideoName.value || '';

        this.$emit('updatePlugin', this.selectedVms);
        setTimeout(() => {
          this.$refs.dkHiveWebrtcPlayer.play(this.webRtcInfo);
          this.ptzLoad();
          this.$refs.fcltPluginStatus.updateFcltStatusInfo(this.selectedVms.fcltId);
        }, 100);
      } catch (err) {
        console.log(err);
      }
    },
    showDialog(fcltData) {
      let me = this;
      this.selectedVms = fcltData;
      this.cctvActivate();

      this.liveViewDialog = true;
      this.socket = io({
        path: '/cameraapi/dk-socket/camera',
        transport: ['websocket'],
      });

      this.socket.on('echo', (response) => {
        console.log(response);
      });
      this.getVmsDownloadedSchedule();
    },
    onloadWebrtcPlayer() {
      this.selectData(this.selectedVms);
    },
    cctvModalClose() {
      this.gimpoCctvProtocolId = '';
      this.$refs.dkHiveWebrtcPlayer.stop();
    },

    downContinusMove(val) {
      let me = this;
      let param = {
        fcltId: this.selectedVms.fcltId,
        cctvId: this.gimpoCctvProtocolId,
        pan: 0,
        tilt: 0,
        zoom: 0,
        focus: 0,
      };

      if (val == 1) {
        param.tilt = 1 * this.controlSpeed;
      } else if (val == 2) {
        param.pan = -1 * this.controlSpeed;
      } else if (val == 3) {
        param.pan = 1 * this.controlSpeed;
      } else if (val == 4) {
        param.tilt = -1 * this.controlSpeed;
      } else if (val == 5) {
        param.zoom = 2;
      } else if (val == 6) {
        param.zoom = -2;
      } else if (val == 7) {
        param.focus = 2;
      } else if (val == 8) {
        param.focus = -2;
      }

      this.socket.emit('moveContinous', param);
      // this.$store.dispatch("gimpoCctv/cameraContinusMove", {continusMove : param})
      // .then(() => {

      //     me.presetInfo = me.$store.getters['gimpoCctv/cameraContinusMove']
      // })
    },
    async upContinusMove() {
      //마우스업 했을때 현 pan , tilt , zoom 받아오기
      let statusInfo = {};
      let param = {
        fcltId: this.selectedVms.fcltId,
        cctvId: this.selectedVms.fcltId,
        pan: 0,
        tilt: 0,
        zoom: 0,
        focus: 0,
      };

      this.socket.emit('moveContinous', param);
      await this.ptzLoad();
    },

    async ptzLoad() {
      let statusInfo = {};
      let statusCctvId = this.selectedVms.fcltId;
      let param = {
        fcltId: this.selectedVms.fcltId,
        cctvId: statusCctvId,
      };
      this.socket.emit('getPtz', param);
    },

    async getVmsDownloadedSchedule() {
      let param = {
        fcltId: this.selectedVms.fcltId,
      };
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsDownloadedSchedule`, {
        params: param,
      });
      this.vmsDownloadedSchedule = response.data;
      this.vmsCurrentSchedule = this.vmsDownloadedSchedule.vmsSchedules.find((schedule) => {
        return schedule.vmsScheduleId == this.vmsDownloadedSchedule.currentScheduleId;
      });
      if (!this.vmsCurrentSchedule) {
        this.vmsCurrentSchedule = {
          vmsMessages: [],
        };
      }
    },
    getVmsMessageLabel(item) {
      let result = vmsConst.vmsMessageType.find((vm) => {
        return item.vmsMessageType === vm.value;
      });
      if (result) return result.label;
      else return '';
    },
  },
};
</script>

<style scoped>
.camera-status-monitoring-wrap {
  background-color: #000;
  height: 40px;
  position: relative;
}

.camera-status-monitoring {
  list-style: none;
  margin: 0;
  position: absolute;
  right: 0;
  top: 10px;
}
.camera-status-monitoring::after {
  clear: both;
  content: '';
  display: block;
}
.camera-status-monitoring li {
  color: #cfcfcf;
  float: left;
  margin: 2px 8px;
}

.camera-status-monitoring li span {
  color: #fff;
  font-size: 20px;
  margin: 0 5px;
}

.camera-info-wrap {
  display: flex;
  flex-direction: row;
  height: 205px;
  padding: 0;
  position: relative;
  width: 100%;
}

.camera-status-wrap {
  flex: 1;
}

.cctv-control-wrap {
  height: 205px;
  margin: 5px 5px 0 0;
  width: 300px;
}

.camera-control-speed-label {
  width: 120px;
}

.control-slider-wrap {
  margin: 10px auto 0 auto;
  width: 260px;
}

.cctv-viewer-wrap {
  -webkit-box-flex: 1;
  box-sizing: border-box;
  display: block;
  -ms-flex: 1;
  flex: 1;
  flex-basis: auto;
  -ms-flex-preferred-size: auto;
  padding: 0;
  position: relative;
}

.ptz-table {
  width: 100%;
}

.ptz-table td,
.ptz-table th {
  text-align: center;
}

.vms-thumbnail-wrap {
  list-style: none;
  margin: 0;
  min-height: 100%;
  padding: 0;
}

.vms-thumbnail-wrap .image-wrap {
  -webkit-box-shadow: 0 0 1px #aaa;
  box-shadow: 0 0 1px #aaa;
  display: flex;
  margin-bottom: 10px;
}

.vms-thumbnail-wrap .text {
  background-color: #fafafa;
  padding: 10px 0;
  width: 100px;
}

.vms-thumbnail-wrap .text p {
  margin: 7px 0;
  padding: 0 5px 0 0;
  text-align: right;
}

.vms-thumbnail-wrap .text .type-text {
  color: #999;
  font-size: 12px;
}

.vms-thumbnail-wrap .image {
  background-color: #000;
  flex: 1;
}
</style>
